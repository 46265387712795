import React, { useState } from "react";
import { useSelector } from "react-redux";
import PT from "prop-types";
import get from "lodash/get";
import { Link } from "@quintype/components";
import MainNavMenuGroup from "./main-nav-group";
import { getDate } from "../../utils";
import { SvgIconHandler } from "../../atoms/svg-icon-hadler";
import { useDeviceType } from "../../custom-hooks/useDeviceType";
import TertiaryMenuGroup from "./tertiary-menu-group";
import { DfpComponent } from "../../ads/dfp-component";

import "./home-page-mast.m.css";

function HomePageMast() {
  const [moreOpen, setMoreOpen] = useState(false);
  const qtState = useSelector((state) => get(state, ["qt", "data"], {}));
  const mainNav = get(qtState, ["navigationMenu", "mainNav"], []);
  const tertiaryNav = get(qtState, ["navigationMenu", "tertiaryNav"], []);
  const offerMenuItem = get(qtState, ["navigationMenu", "offerMenu"], []);
  const deviceType = useDeviceType();
  const isTablet = deviceType === "tablet";
  const logoDimension = isTablet ? { width: "300px", height: "43.7px" } : { width: "350px", height: "51px" };
  const logoDimensionViewPort = isTablet ? "0 0 300 43.7" : "0 0 350 51";
  const homePageMastAdConfig = useSelector((state) =>
    get(state, ["qt", "config", "ads-config", "slots", "ad_homepage_mast"], {})
  );
  const pageType = useSelector((state) => get(state, ["qt", "pageType"], null));

  const handleShowMore = () => {
    setMoreOpen(!moreOpen);
  };

  return (
    <div styleName="mast-container">
      <nav styleName="home-mast-wrapper">
        <div styleName="logo-div">
          <div>
            <DfpComponent
              adStyleName="ad-slot-size-160x90"
              id={`${pageType}-mast-ad-1`}
              path={homePageMastAdConfig.ad_unit}
              size={homePageMastAdConfig.sizes}
              viewPortSizeMapping={homePageMastAdConfig.view_port_size_mapping}
              isSponsored={true}
            />
          </div>
          <Link href="/" styleName="center-mast">
            <SvgIconHandler
              type="mast-logo"
              width={logoDimension.width}
              height={logoDimension.height}
              viewBox={logoDimensionViewPort}
            />
            <div styleName="time-stamp">{getDate()}</div>
          </Link>
          <div>
            <DfpComponent
              adStyleName="ad-slot-size-160x90"
              id={`${pageType}-mast-ad-2`}
              path={homePageMastAdConfig.ad_unit}
              size={homePageMastAdConfig.sizes}
              viewPortSizeMapping={homePageMastAdConfig.view_port_size_mapping}
              isSponsored={true}
            />
          </div>
        </div>
        <div styleName="mobile-menu-groups">
          <TertiaryMenuGroup menuList={tertiaryNav} offerMenuItem={offerMenuItem} />
          <MainNavMenuGroup menuList={mainNav} moreOpen={moreOpen} handleMoreOpen={handleShowMore} />
        </div>
        <div styleName="menu-groups">
          <MainNavMenuGroup menuList={mainNav} moreOpen={moreOpen} handleMoreOpen={handleShowMore} />
          <TertiaryMenuGroup menuList={tertiaryNav} offerMenuItem={offerMenuItem} />
        </div>
      </nav>
    </div>
  );
}

HomePageMast.propTypes = {
  isScrolled: PT.bool,
};

export default HomePageMast;
