import React, { useState, useRef, useLayoutEffect } from "react";
import PT from "prop-types";
import { NavMoreClose } from "../../../atoms/Nav-More-Close";
import { NavMore } from "../../../atoms/Nav-More";
import { useDeviceType } from "../../../custom-hooks/useDeviceType";
import MenuItem from "../home-menu-item";
import "./main-nav-menu-group.m.css";

function MainNavMenuGroup({ menuList, moreOpen, handleMoreOpen }) {
  const [showMoreButton, setShowMoreButton] = useState(false);

  const listItemRef = useRef(null);
  const deviceType = useDeviceType();

  useLayoutEffect(() => {
    deviceType && listItemRef?.current?.offsetTop > 0 ? setShowMoreButton(true) : setShowMoreButton(false);
  }, [deviceType]);

  return (
    <nav styleName="main-nav-wrapper">
      <ul styleName={`menu-group  ${moreOpen ? "show-more" : "show-less"}`}>
        {menuList?.map((item, index) => (
          <li key={item.title} ref={index === menuList.length - 1 ? listItemRef : null}>
            <MenuItem item={item} menuStyle="main-menu-link" />
          </li>
        ))}
      </ul>
      {showMoreButton && (
        <button styleName="more-btn" onClick={handleMoreOpen}>
          {moreOpen ? <NavMoreClose /> : <NavMore />}
        </button>
      )}
    </nav>
  );
}

export default MainNavMenuGroup;

MainNavMenuGroup.propTypes = {
  menuList: PT.array,
};
