/* eslint-disable react/no-unknown-property */
import React from "react";
import { MenuItem } from "../menu-item";
import { OPEN_SEARCHBAR } from "../../store/actions";
import { useDispatch } from "react-redux";
import PT from "prop-types";
import "./menu-group.m.css";

function MenuGroup({ menuList, menuStyle = "menu-link", textColor }) {
  const dispatch = useDispatch();

  const toggleHandler = () => {
    dispatch({
      type: OPEN_SEARCHBAR,
      isSearchBarOpen: false,
    });
  };
  const appDownloadItem = menuList?.find((item) => item?.data?.["icon-code"] === "app-download");
  const newMenuList = appDownloadItem ? menuList?.slice(0, -1) : menuList;
  return (
    <ul id="menu-list" styleName="menu-group">
      {newMenuList?.map((item) => (
        <li key={item.title}>
          <MenuItem item={item} menuStyle={menuStyle} textColor={textColor} onClick={toggleHandler} />
        </li>
      ))}
      {appDownloadItem && (
        <li key={appDownloadItem.title}>
          <MenuItem item={appDownloadItem} menuStyle={menuStyle} textColor={textColor} onClick={toggleHandler} />
        </li>
      )}
    </ul>
  );
}

export default MenuGroup;

MenuGroup.propTypes = {
  menuList: PT.array,
  isMobileNavMenu: PT.bool,
};
