import axios from "axios";
import { differenceInHours, differenceInMinutes } from "date-fns";
import get from "lodash/get";
import {
  AD_FREQUENCY,
  FIRST_AD_POSITION,
  PARTNER_CONTENT_STORY_TEMPLATE,
  TEMPLATES_WITH_PARAGRAPH_ADS,
  TEMPLATES_WITH_STORY_ELEMENT_ADS,
} from "../../constants";

export const getStoryLabel = (story) => {
  if (story && story["story-template"] === PARTNER_CONTENT_STORY_TEMPLATE) {
    return "Partner Content";
  }
  const label = get(story, ["metadata", "story-attributes", "label", "0"], "");
  return label;
};

export const getLastPublishedTime = (timestamp) => {
  const hourDifference = differenceInHours(new Date(), new Date(timestamp));
  if (hourDifference > 2) {
    return null;
  }
  const minutesDifference = differenceInMinutes(new Date(), new Date(timestamp));
  if (minutesDifference < 5) {
    return "Just now";
  }
  if (minutesDifference < 60) {
    return `${minutesDifference}m ago`;
  }
  return `${hourDifference}h ago`;
};

export const getStoryHeadline = (story) => {
  const headline = story && get(story, ["alternative", "home", "default", "headline"], story?.headline);
  return headline || story?.headline;
};

export const fetchRSSFeed = async (url, playListId) => {
  try {
    const response = await axios.get(`${url}${playListId}`);
    const text = response.data;
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(text, "text/xml");

    if (xmlDoc.querySelector("parsererror")) {
      throw new Error("Error parsing XML");
    }

    const items = xmlDoc.querySelectorAll("entry");

    const feedItems = Array.from(items).map((item) => ({
      id: item.querySelector("id")?.textContent,
      title: item.querySelector("title")?.textContent,
      videoId: item.querySelector("videoId")?.textContent,
      videoUrl: item.querySelector("link")?.getAttribute("href"),
      thumbnail: item.querySelector("thumbnail")?.getAttribute("url"),
    }));
    return feedItems;
  } catch (error) {
    console.error("Error fetching or parsing RSS feed:", error);
    throw error;
  }
};

export const getRecipeCookTime = (story) => {
  if (story && story["story-template"] === "recipe") {
    const cookTime = get(story, ["metadata", "story-attributes", "cookingtime", "0"], "");
    return cookTime;
  }
  return "";
};

export const getRecipeCuisine = (story) => {
  const cuisine = story && get(story, ["metadata", "story-attributes", "cuisine", "0"], "");
  return cuisine;
};
export const getAttribution = (html) => {
  if (typeof window !== "undefined") {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const pBodyEle = doc.querySelector("body");
    const pElement = doc.querySelector("div.paragraphs p");

    if (pBodyEle.querySelector("div")) {
      return pElement && pElement?.textContent?.trim() !== "" && html;
    }
    return pBodyEle?.innerHTML && html;
  } else {
    const textContent = html?.replace(/<[^>]+>/g, "").trim();
    return html && textContent?.length && html;
  }
};

export function isExternalStory(story = {}) {
  const { "story-template": storyTemplate = "", metadata = {} } = story;
  return storyTemplate === "news-elsewhere" ? get(metadata, ["reference-url"], "") : "";
}

export const getStoryUrl = (story, defaultValue = "") => {
  return get(story, ["story-template"]) === "news-elsewhere"
    ? get(story, ["metadata", "reference-url"], "")
    : story.url || defaultValue;
};

export const isNonEmptyNode = (htmlNode) => {
  if (typeof window !== "undefined") {
    const tempDiv = window.document.createElement("div");
    tempDiv.innerHTML = htmlNode;
    const hasContent = tempDiv?.textContent;
    return hasContent?.trim(" ")?.length > 0 && htmlNode;
  } else {
    const textContent = htmlNode?.replace(/<[^>]+>/g, "").trim();
    return htmlNode && textContent?.length && htmlNode;
  }
};

export const getLinksFromString = (str) => {
  const pattern = /<a\s+href="([^"]*)">([^<]*)<\/a>/g;
  const matches = [...str.matchAll(pattern)];
  const result = [];

  for (const match of matches) {
    const url = match[1];
    result.push(url);
  }
  return result;
};

export const getCardByAttribute = (story, attribute) => {
  return story.cards.find((card) => {
    const cardtypes = get(card, ["metadata", "attributes", "cardtype"], []);
    return cardtypes.includes(attribute);
  });
};

export const storyTemplates = {
  recipe: "recipe",
  listicle: "listicle",
  storyListicle: "story-listicle",
};

export const getStoryTemplate = (story = {}) => {
  return story?.["story-template"];
};

export const extractParagraphsFromStory = (story, count = 2) => {
  const paragraphs = [];
  for (const card of story?.cards) {
    for (const element of card["story-elements"]) {
      if (element.type === "text") {
        const foundParagraphs = element.text.match(/(<p\b[^>]*>(.*?)<\/p>)/g); // Find all <p>...</p> elements
        if (foundParagraphs) {
          paragraphs.push(...foundParagraphs.slice(0, count - paragraphs.length));
        }
      }
      if (paragraphs.length >= count) {
        break;
      }
    }
  }
  return paragraphs;
};

export const isAdPosition = (counter) => {
  const isFirstAdPosition = counter?.paragraphs === FIRST_AD_POSITION;
  const isCurrentAdPosition =
    counter?.paragraphs > FIRST_AD_POSITION && (counter?.paragraphs - FIRST_AD_POSITION) % AD_FREQUENCY === 0;
  return isCurrentAdPosition || isFirstAdPosition;
};

export const isTemplateWithAds = (story) => {
  const isStoryWithNoAds = get(story, ["metadata", "story-attributes", "noads", "0"]) === "true";
  if (isStoryWithNoAds) {
    return false;
  }
  const template = story?.["story-template"];

  return TEMPLATES_WITH_PARAGRAPH_ADS.includes(template) || TEMPLATES_WITH_STORY_ELEMENT_ADS.includes(template);
};

export function extractElements(htmlString) {
  const regex = /<([^>]+)>(.*?)<\/\1>/g;
  const elements = [];
  let match;

  while ((match = regex.exec(htmlString)) !== null) {
    const tagName = match[1]; // The tag name (e.g., h2, p, etc.)
    const text = match[0]; // The full element (e.g., <h2>...</h2>)

    elements.push({ tagName, text });
  }

  return elements;
}
