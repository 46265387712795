import React, { useState, useEffect } from "react";
import { useDeviceType } from "../../custom-hooks/useDeviceType";
import NavBar from "./nav-bar";

export default function Header() {
  const [isScrolled, setIsScrolled] = useState(false);
  const deviceType = useDeviceType();
  const [topDivHeight, setTopDivHeight] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = document.documentElement.scrollTop;
      if (deviceType === "desktop") {
        scrollTop >= 385 && !isScrolled && setIsScrolled(true);
        scrollTop < 345 && isScrolled && setIsScrolled(false);
      } else if (deviceType === "tablet") {
        scrollTop >= 174 && !isScrolled && setIsScrolled(true);
        scrollTop < 134 && isScrolled && setIsScrolled(false);
      }
      setTopDivHeight(scrollTop);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isScrolled, deviceType]);

  return <NavBar isScrolled={isScrolled} topDivHeight={topDivHeight} />;
}
