import { STORY_FIELDS } from "../isomorphic/constants";

export const getSearchPageItems = async (slug, offset, limit) => {
  const { results } = await (await fetch(`/api/v1/search?q=${slug}&offset=${offset}&limit=${limit + 1}`)).json();
  const loadMoreStories = results.stories.map((story) => {
    return { type: "story", story: story };
  });
  return loadMoreStories;
};

export const getAdvanceSearchPageItems = async (
  slug,
  offset,
  limit,
  sort = "latest-published",
  sectionName,
  cuisineFilter,
  prepTimeFilterValue,
  cookTimeFilterValue
) => {
  const cuisineFilterVal = cuisineFilter && cuisineFilter !== "All" ? `&story-attributes.cuisine=${cuisineFilter}` : "";
  const prepTimeFilterVal =
    prepTimeFilterValue && prepTimeFilterValue !== "All"
      ? `&story-attributes.preparationtime=${prepTimeFilterValue}`
      : "";
  const cookTimeFilterVal =
    cookTimeFilterValue && cookTimeFilterValue !== "All" ? `&story-attributes.cookingtime=${cookTimeFilterValue}` : "";

  const results = await (
    await fetch(
      `/api/v1/advanced-search?${
        slug ? `q=${slug}&` : ""
      }offset=${offset}&limit=${limit}&sort=${sort}&aggregations=section-name,story-attributes.cuisine,story-attributes.cookingtime,story-attributes.preparationtime${
        sectionName ? `&section-name=${sectionName}` : ""
      }${cuisineFilterVal}${prepTimeFilterVal}${cookTimeFilterVal}`
    )
  ).json();
  return results;
};

export const getStories = async (query, slug, offset, limit) => {
  const { stories } = await (
    await fetch(`/api/v1/stories?${query}=${slug}&offset=${offset}&limit=${limit + 1}`)
  ).json();
  const loadMoreStories = stories.map((story) => {
    return { type: "story", story: story };
  });
  return loadMoreStories;
};

export const getCollectionitems = async (slug, offset, limit) => {
  const { items, "total-count": totalCount } = await (
    await fetch(`/api/v1/collections/${slug}?&item-type=story&offset=${offset}&limit=${limit + 1}`)
  ).json();
  return { items, totalCount };
};

export const getMenuGroups = async () => {
  const result = await (await fetch(`/api/v1/menu-groups`)).json();
  return result;
};

export const getAuthorStories = async (id, offset, limit) => {
  const { items } = await (
    await fetch(`/api/v1/authors/${id}/collection?&item-type=story&offset=${offset}&limit=${limit}`)
  ).json();
  return items;
};

export const loadRelatedStories = async (story, config) => {
  const { mountAt } = config || {};
  const mountAtPrefix = mountAt || "";
  const sectionQuery = story.sections && story.sections.length !== 0 ? `section-id=${story.sections[0].id}` : "";
  const response = await global
    .wretch(`${mountAtPrefix}/api/v1/stories/${story.id}/related-stories?limit=4&${sectionQuery}`)
    .query({
      fields: STORY_FIELDS,
    })
    .get()
    .json((relatedStories) => relatedStories["related-stories"]);
  return response;
};

export const getDubaiTemperature = async () => {
  const response = await fetch("/dubai-weather");
  const result = await response.json();
  return `${result?.data?.main?.temp.toString().slice(0, 2)}\u00B0C`;
};

export const getTrendingStories = async () => {
  const { name, items } = await (await fetch(`/api/v1/collections/trending-stories?limit=6`)).json();
  return { name, items };
};

export const getLatestStories = async () => {
  const { name, items } = await (await fetch(`/api/v1/collections/latest-stories?limit=6`)).json();
  return { name, items };
};

export const getCoverImage = async (sectionSlug) => {
  const { metadata } = await (await fetch(`/api/v1/collections/${sectionSlug}`)).json();
  return { coverImage: metadata?.["cover-image"] || "" };
};

export const getLiveStoryData = async (storyId) => {
  const { story } = await (await fetch(`/api/v1/stories/${storyId}`)).json();
  return { story };
};
