import React, { useState } from "react";
import NestedMenuItems from "./nested-menu-items";
import SideMenuItem from "./side-menu-item";
import PT from "prop-types";
import "./side-menu.m.css";

export default function SideMenuGroup({ menuItem, toggleHandler, textColor }) {
  const [showPrimaryMenuSection, setShowPrimaryMenuSection] = useState("");

  const showPrimarySectionHandler = (idx) => {
    setShowPrimaryMenuSection(showPrimaryMenuSection === idx ? undefined : idx);
  };

  return menuItem?.map((item, index) => (
    <>
      <SideMenuItem
        key={item.id}
        index={index}
        item={item}
        toggleHandler={toggleHandler}
        showMenuSection={showPrimaryMenuSection}
        showSectionHandler={showPrimarySectionHandler}
        textColor={textColor}
      />
      {showPrimaryMenuSection === index && (
        <div styleName="submenu">
          <NestedMenuItems key={index} item={item} toggleHandler={toggleHandler} />
        </div>
      )}
    </>
  ));
}

SideMenuGroup.propTypes = {
  menuItem: PT.array,
  toggleHandler: PT.func,
};
