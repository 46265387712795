import React from "react";
import { useSelector } from "react-redux";
import get from "lodash/get";
import { DfpComponent } from "../dfp-component";
import { useDeviceType } from "../../custom-hooks/useDeviceType";

const AdsFiller = () => {
  const pageType = useSelector((state) => get(state, ["qt", "pageType"], null));

  const getAdKey = (pageType) => {
    switch (pageType) {
      case "home-page":
        return "home_top_filler_ad";
      case "story-page":
        return "story_top_filler_ad";
      default:
        return "section_top_filler_ad";
    }
  };

  const topFillerAdConfig = useSelector((state) =>
    get(state, ["qt", "config", "ads-config", "slots", getAdKey(pageType)], {})
  );

  const deviceType = useDeviceType();
  const isMobile = deviceType === "mobile";
  const isTablet = deviceType === "tablet";

  if (isMobile || isTablet) {
    return null;
  }

  return (
    <div>
      <DfpComponent
        adStyleName="top-ad-filler"
        id={`${pageType}-top-filler-ad`}
        path={topFillerAdConfig.ad_unit}
        size={topFillerAdConfig.sizes}
        viewPortSizeMapping={topFillerAdConfig.view_port_size_mapping}
        isSponsored={true}
        centerText={true}
      />
    </div>
  );
};

AdsFiller.propTypes = {};

export { AdsFiller };
