import React from "react";
import { SvgIconHandler } from "../svg-icon-hadler";
import "./nav-more.m.css";

export const NavMore = () => {
  return (
    <div styleName="more-wrapper">
      <span styleName="more-label">MORE</span>
      <span styleName="icon-color">
        <SvgIconHandler type="arrow-down" width="24px" height="24px" viewBox="0 0 24 24" />
      </span>
    </div>
  );
};
