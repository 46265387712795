import { useState, useEffect } from "react";

export const useDeviceType = () => {
  const [deviceType, setDeviceType] = useState("desktop");

  const determineDeviceType = () => {
    const width = window.innerWidth;
    if (width < 768) {
      setDeviceType("mobile");
    } else if (width < 1200) {
      setDeviceType("tablet");
    } else {
      setDeviceType("desktop");
    }
  };

  useEffect(() => {
    determineDeviceType();
    window.addEventListener("resize", determineDeviceType);
    return () => {
      window.removeEventListener("resize", determineDeviceType);
    };
  }, []);

  return deviceType;
};
