import get from "lodash/get";

const getSectionId = (state, pageType) => {
  if (pageType === "story-page") {
    return get(state, ["data", "story", "sections", 0, "id"], "NA");
  } else if (pageType === "section-page") {
    return get(state, ["data", "section", "id"], "NA");
  }
  return "NA";
};
const getParentSection = (state, parentId) => {
  const parentSectionGroup = get(state, ["data", "config", "sections"], []);
  const parentSection = parentSectionGroup.find((section) => section.id === parentId);
  return parentSection ? parentSection.name : "";
};

const getSectionList = (state, pageType) => {
  if (pageType === "story-page") {
    const sections = get(state, ["data", "story", "sections"], []);
    const sectionList = sections.map((item) => {
      if (item["parent-id"] !== null) {
        const parentSectionName = getParentSection(state, item["parent-id"]);
        return `${parentSectionName}, ${item.name}`;
      }
      return item.name;
    });
    return sectionList.join(", ");
  }
  return [];
};

function getCookieValue(name = "_cscUserId") {
  const cookieString = document.cookie;
  const cookies = cookieString.split("; ");
  for (const cookie of cookies) {
    const [key, value] = cookie.split("=");
    if (key === name) {
      return value === `''` ? null : value;
    }
  }
  return null;
}

const getTagList = (state, pageType) => {
  if (pageType === "story-page") {
    const tags = get(state, ["data", "story", "tags"], []);
    const tagList = tags.map((item) => item.name);
    return tagList;
  }
  return [];
};

const getArticleId = (state, pageType) => {
  if (pageType === "story-page") {
    return get(state, ["data", "story", "public-identifier"], "NA");
  }
  return "NA";
};
const getArticleLabel = (state, pageType) => {
  if (pageType === "story-page") {
    return get(state, ["data", "story", "metadata", "story-attributes", "label", 0], "NA");
  }
  return "NA";
};
const getArticleType = (state, pageType) => {
  if (pageType === "story-page") {
    return get(state, ["data", "story", "story-template"], "NA");
  }
  return "NA";
};

export const setViewportSizeMapping = (adSlot, googletag, viewPortSizeMapping) => {
  const sizeMappingBuilder = googletag.sizeMapping();

  viewPortSizeMapping.forEach((mapping) => {
    sizeMappingBuilder.addSize(mapping.viewport, mapping.sizes);
  });

  const mapping = sizeMappingBuilder.build();
  adSlot.defineSizeMapping(mapping);
};

export const generateLazyLoading = (googletag, fetchMarginPercent, renderMarginPercent, mobileScaling) => {
  const updateSlotStatus = (slotId, state) => {
    const elem = document.getElementById(slotId + "-" + state);
    if (elem) {
      elem.className = "activated";
      elem.innerText = "Yes";
    }
  };

  if (googletag && googletag.pubadsReady) {
    // detect whether PubAdsService is fully loaded
    googletag.pubads().enableLazyLoad({
      fetchMarginPercent, // Fetch slots within specified viewports
      renderMarginPercent, // Render slots within specified viewports
      mobileScaling, // Multiplies the specified value with the above values for mobile
    });

    googletag.pubads().addEventListener("slotRequested", function (event) {
      updateSlotStatus(event.slot.getSlotElementId(), "fetched");
    });

    googletag.pubads().addEventListener("slotOnload", function (event) {
      updateSlotStatus(event.slot.getSlotElementId(), "rendered");
    });
  }
};

export const setTargetingParams = (count = 0, googletag, adSlot, qtState) => {
  const pageType = get(qtState, ["pageType"], "");
  const environment = get(qtState, ["config", "publisher-attributes", "env"], "");
  const articleId = getArticleId(qtState, pageType);
  const articleType = getArticleType(qtState, pageType);
  const articleLabel = getArticleLabel(qtState, pageType);
  const sectionId = getSectionId(qtState, pageType);
  const sectionList = getSectionList(qtState, pageType);
  const tagList = getTagList(qtState, pageType);
  adSlot
    .addService(googletag.pubads())
    .setTargeting("pageType", pageType)
    .setTargeting("environment", environment)
    .setTargeting("sectionId", sectionId)
    .setTargeting("pageSection", sectionList)
    .setTargeting("tagList", tagList)
    .setTargeting("articleId", articleId)
    .setTargeting("articleType", articleType)
    .setTargeting("articleLabel", articleLabel)
    .setTargeting("UUID", getCookieValue())
    .setTargeting("Logged In", getCookieValue() ? true : "false")
    .setTargeting("paragraph-ad-count", count);
};

export const useDfpSlot = ({
  path,
  size,
  id,
  qtState,
  viewPortSizeMapping,
  storySectionSlug,
  refreshAdUnit,
  pageSection,
  pageSubSection,
  pageSubSubSection,
  articleId,
  articleType,
  articleLabel,
  cxSegments,
  loggedIn,
  lastLoggedInDate,
  subscribed,
  count,
}) => {
  if (refreshAdUnit && window.googletag && window.googletag.apiReady) {
    // check if the API is ready
    window.googletag.cmd.push(function () {
      if (window.googletag.pubadsReady) {
        // detect whether PubAdsService is fully loaded
        const existingSlot = window.googletag
          .pubads()
          .getSlots()
          .filter((item) => id === item.getSlotElementId());

        window.googletag.pubads().refresh(existingSlot);
      }
    });
  }

  window.googletag.cmd.push(function () {
    const responsiveAdSlot = window.googletag.defineSlot(path, size, id);
    const adsConfig = get(qtState, ["config", "ads-config", "dfp_ads"], {});
    const enableLazyLoadAds = get(adsConfig, ["enable_lazy_load_ads"]);
    const fetchMarginPercent = get(adsConfig, ["fetch_margin_percent"], 0);
    const renderMarginPercent = get(adsConfig, ["render_margin_percent"], 0);
    const mobileScaling = get(adsConfig, ["mobile_scaling"], 0);
    if (responsiveAdSlot) {
      setTargetingParams(
        count,
        window.googletag,
        responsiveAdSlot,
        qtState,
        storySectionSlug,
        pageSection,
        pageSubSection,
        pageSubSubSection,
        articleId,
        articleType,
        articleLabel,
        cxSegments,
        loggedIn,
        lastLoggedInDate,
        subscribed
      );
      setViewportSizeMapping(responsiveAdSlot, window.googletag, viewPortSizeMapping);
      if (enableLazyLoadAds) {
        generateLazyLoading(window.googletag, fetchMarginPercent, renderMarginPercent, mobileScaling);
      }
      window.googletag.enableServices();
    }
  });

  window.googletag.cmd.push(function () {
    window.googletag.display(id);
  });
};

export const appendGoogleTagServices = () => {
  const script = document.createElement("script");
  const isSSL = document.location.protocol === "https:";
  script.src = (isSSL ? "https:" : "http:") + "//www.googletagservices.com/tag/js/gpt.js";
  const node = document.getElementsByTagName("script")[0];
  script.setAttribute("async", "");
  node.parentNode.insertBefore(script, node);
};

export const getAdSlots = ({
  path,
  size,
  id,
  qtState,
  type,
  viewPortSizeMapping,
  storySectionSlug,
  loadAdsSynchronously,
  delayPeriod,
  refreshAdUnit,
  pageSection,
  pageSubSection,
  pageSubSubSection,
  articleId,
  articleType,
  articleLabel,
  cxSegments,
  loggedIn,
  lastLoggedInDate,
  subscribed,
  count,
}) => {
  if (loadAdsSynchronously) {
    useDfpSlot({
      path: path,
      size: size,
      id: id,
      qtState: qtState,
      type: type,
      viewPortSizeMapping: viewPortSizeMapping,
      storySectionSlug,
      refreshAdUnit,
      pageSection: pageSection,
      pageSubSection: pageSubSection,
      pageSubSubSection: pageSubSubSection,
      articleId: articleId,
      articleType: articleType,
      articleLabel: articleLabel,
      cxSegments: cxSegments,
      loggedIn: loggedIn,
      lastLoggedInDate: lastLoggedInDate,
      subscribed: subscribed,
      count,
    });
  } else {
    setTimeout(() => {
      useDfpSlot({
        path: path,
        size: size,
        id: id,
        qtState: qtState,
        type: type,
        viewPortSizeMapping: viewPortSizeMapping,
        storySectionSlug,
        refreshAdUnit,
        pageSection: pageSection,
        pageSubSection: pageSubSection,
        pageSubSubSection: pageSubSubSection,
        articleId: articleId,
        articleType: articleType,
        articleLabel: articleLabel,
        cxSegments: cxSegments,
        loggedIn: loggedIn,
        lastLoggedInDate: lastLoggedInDate,
        subscribed: subscribed,
        count,
      });
    }, delayPeriod);
  }
};
