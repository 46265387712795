import React, { useEffect } from "react";
import { string, array } from "prop-types";
import { useSelector } from "react-redux";
import get from "lodash/get";

import { getAdSlots } from "../utils";
import { SvgIconHandler } from "../../atoms/svg-icon-hadler";

import "./dfp-component.m.css";

const DfpComponent = ({
  adStyleName,
  containerStyle = "",
  id,
  size,
  path,
  type = "",
  viewPortSizeMapping,
  customStyle = {},
  isSponsored = false,
  isStickyAd = false,
  isSticky = false,
  count = 0,
  centerText = false,
}) => {
  const qtState = useSelector((state) => get(state, ["qt"], {}));
  const adsConfig = get(qtState, ["config", "ads-config", "dfp_ads"], {});
  const loadAdsSynchronously = get(adsConfig, ["load_ads_synchronously"], null);
  const enableAds = get(adsConfig, ["enable_ads"], null);
  const currentPath = get(qtState, ["currentPath"], null);
  const delayAdLoad = get(adsConfig, ["delay_ad_load"], 4);
  const hideAdText = isSponsored || adStyleName === "ad-slot-size-100x60";
  const [hideAd, setHideAd] = React.useState(false);

  if (!enableAds) {
    return null;
  }
  const stickyPageAd = isStickyAd ? `ad-slot ${adStyleName} sticky-ad-bottom` : `ad-slot ${adStyleName}`;
  const containerStyleName = containerStyle ? `ad-slot-container ${containerStyle}` : "ad-slot-container";

  useEffect(() => {
    getAdSlots({
      path,
      size,
      id,
      qtState,
      type,
      viewPortSizeMapping,
      loadAdsSynchronously,
      delayPeriod: delayAdLoad * 1000,
      count,
    });
  }, [currentPath]);

  const handleCloseClick = () => {
    setHideAd(true);
  };

  return (
    <div styleName={containerStyleName} style={hideAd ? { display: "none" } : {}}>
      {!hideAdText && !centerText && <div styleName="ad-text">ADVERTISEMENT</div>}
      {isSticky && (
        <div onClick={handleCloseClick} styleName="ad-close">
          <SvgIconHandler type={"close"} width="22" height="22" iconStyle={{ color: "#596E79" }} />
        </div>
      )}
      <div styleName={stickyPageAd} id={id} style={customStyle}>
        {centerText && <div styleName="ad-text center-text">ADVERTISEMENT</div>}
      </div>
    </div>
  );
};

DfpComponent.propTypes = {
  adStyleName: string,
  id: string,
  size: array,
  path: string,
  type: string,
  viewPortSizeMapping: array,
};

export { DfpComponent };
