import React from "react";
import { bool, array, object, string } from "prop-types";
import get from "lodash/get";
import { connect } from "react-redux";

import "./breaking-news.m.css";

const renderBreakingNews = (breakingNews, breakingNewsConfig) => {
  const items = breakingNews.map((story) => {
    const linkedStorySlug = get(story, ["metadata", "linked-story-slug"], null);
    return (
      <div key={story.id}>
        <a href={linkedStorySlug}>
          <span styleName="title">{story.headline}</span>
        </a>
      </div>
    );
  });

  return <div>{items}</div>;
};
export const BreakingNewsViewBase = ({ breakingNews = [], breakingNewsConfig = {}, pageType }) => {
  const shouldBreakingNewsShow = breakingNews.length === 0 || pageType !== "home-page";
  const breakingNewsCount = breakingNewsConfig.item_display;
  const breakingNewsItem = breakingNewsCount ? breakingNews.slice(0, breakingNewsCount) : breakingNews;

  if (shouldBreakingNewsShow) {
    return <div className="empty-div-margin-bottom"></div>;
  }

  return (
    <div className="container">
      <div styleName="container">
        <div styleName="breaking-news-label">BREAKING NEWS</div>
        {renderBreakingNews(breakingNewsItem, breakingNewsConfig)}
      </div>
    </div>
  );
};

BreakingNewsViewBase.propTypes = {
  breakingNewsLoaded: bool,
  breakingNews: array,
  breakingNewsConfig: object,
  pageType: string,
};

const mapStateToProps = (state) => ({
  pageType: get(state, ["qt", "pageType"], null),
  breakingNewsConfig: get(state, ["qt", "config", "publisher-attributes", "breaking_news"], {}),
});

export const BreakingNewsView = connect(mapStateToProps, () => ({}))(BreakingNewsViewBase);
