/* eslint-disable react/no-unknown-property */
import React from "react";
import PT from "prop-types";
import get from "lodash/get";
import { Link } from "@quintype/components";
import { SvgIconHandler } from "../../../atoms/svg-icon-hadler";
import MenuItem from "../home-menu-item";
import "./tertiary-menu-group.m.css";

function TertiaryMenuGroup({ menuList, offerMenuItem }) {
  const iconCode = get(offerMenuItem[0], ["data", "icon-code"]) || "";
  const bgColor = get(offerMenuItem[0], ["data", "color"]) || "";
  return (
    <nav styleName={`${offerMenuItem.length > 0 ? "tertiary-menu-wrapper" : "tertiary-wrapper"}`}>
      <ul styleName="menu-group">
        {menuList?.map((item) => (
          <li key={item.title}>
            <MenuItem item={item} menuStyle="tertiary-menu-link" textColor="#596E79" />
          </li>
        ))}
      </ul>
      <div id="offer-item" styleName="offer-item">
        <div style={{ background: bgColor }}>
          <Link
            styleName="offer-menu-link"
            aria-label="menu-item"
            className="menu-link"
            href={offerMenuItem[0]?.completeUrl}
          >
            <>
              <span style={{ color: "#FFF" }}>{offerMenuItem[0]?.title}</span>
              {iconCode && (
                <span style={{ color: "#FFF" }}>
                  <SvgIconHandler type={iconCode} width="24px" height="24px" viewBox="0 0 24 24" />
                </span>
              )}
            </>
          </Link>
        </div>
      </div>
    </nav>
  );
}

export default TertiaryMenuGroup;

TertiaryMenuGroup.propTypes = {
  menuList: PT.array,
};
