import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PT from "prop-types";
import get from "lodash/get";
import { Link } from "@quintype/components";
import { OPEN_SEARCHBAR, OPEN_HAMBURGER_MENU, MEMBER_UPDATED } from "../../../store/actions";
import { useDeviceType } from "../../../custom-hooks/useDeviceType";
import { SvgIconHandler } from "../../../atoms/svg-icon-hadler";
import { Logo } from "../../../atoms/svg-icon-hadler/Icon";
import Button from "../../../atoms/Button";
import NavbarSearch from "../navbar-search";
import MenuGroup from "../../menu-group";
import SideMenu from "../side-menu";
import { AdsFiller } from "../../../ads/ads-filler";
import { getUserDetails, loginHandler, SUBSCRIPTIONS_LINK } from "../../../paywall";
import { UserProfile } from "../user-profile";
import "./navbar.m.css";

function NavBar({ isScrolled, topDivHeight }) {
  const pageType = useSelector((state) => get(state, ["qt", "pageType"], null));
  const qtState = useSelector((state) => get(state, ["qt", "data"], {}));
  const clientId = useSelector((state) =>
    get(state, ["qt", "config", "publisher-attributes", "conscent_configuration", "client_id"], "")
  );
  const storytemplate = get(qtState, ["story", "story-template"], "text");
  const isSearchBarOpen = useSelector((state) => get(state, ["isSearchBarOpen"], false));
  const isHamburgerMenuOpen = useSelector((state) => get(state, ["isHamburgerMenuOpen"], false));
  const topNavMenu = get(qtState, ["navigationMenu", "topNav"], []);
  const sectionName = (qtState.section && qtState?.section["display-name"]) || "Section Name";
  const member = useSelector((state) => get(state, ["member"], null));
  const deviceType = useDeviceType();
  const isMobile = deviceType === "mobile";
  const dispatch = useDispatch();

  const toggleSearchBar = () => {
    dispatch({
      type: OPEN_SEARCHBAR,
      isSearchBarOpen: !isSearchBarOpen,
    });
  };

  const toggleHamMenu = () => {
    dispatch({
      type: OPEN_HAMBURGER_MENU,
      isHamburgerMenuOpen: !isHamburgerMenuOpen,
    });
  };
  const homepageNavStyle = isScrolled ? "nav-logo-scrolled" : "home-nav-logo";
  const searchBarNavMenus = isSearchBarOpen ? "alter-nav-menus" : `${isScrolled ? "nav-menus-scrolled" : "nav-menus"}`;
  const searchbarNavList = isScrolled
    ? pageType === "section-page"
      ? "section-page-scrolled"
      : "nav-button-wrapper-scrolled"
    : "nav-button-wrapper";

  const hideButtons = (pageType === "section-page" && isMobile && isScrolled) || isSearchBarOpen;
  const hideButtonDiv = {
    display: hideButtons ? "none" : "flex",
  };

  useEffect(() => {
    const onSuccessUserDetailsCallback = async (userDetailsObject) => {
      if (userDetailsObject.statusCode !== 401) {
        dispatch({ type: MEMBER_UPDATED, member: userDetailsObject });
      }
    };
    getUserDetails(onSuccessUserDetailsCallback);
  }, []);

  return (
    <>
      {storytemplate !== "gn-reach" && <AdsFiller></AdsFiller>}
      <div className="container" styleName={`${isScrolled ? "container-scrolled" : "container"}`}>
        <nav id="navigation-bar" styleName={`${isScrolled ? "nav-wrapper-scrolled" : "nav-wrapper"}`}>
          <div styleName={`${isScrolled ? "nav-menu-wrapper-scrolled" : "nav-menu-wrapper"}`}>
            <Button id="hamburger-btn" styleName="hamburger__btn" onClick={toggleHamMenu}>
              <SvgIconHandler type="hamburger" width="32px" height="32px" viewBox="0 0 32 32" />
            </Button>
            {pageType === "home-page" ? (
              <>
                <Link href="/" styleName={homepageNavStyle}>
                  {isScrolled && <Logo />}
                </Link>
                <div styleName={searchBarNavMenus}>
                  <MenuGroup menuList={topNavMenu.slice(0, 3)} menuStyle="nav-menu-link" />
                </div>
              </>
            ) : (
              <Link href="/" styleName="nav-logo-scrolled">
                <Logo />
              </Link>
            )}
            <Link href="/" styleName={isSearchBarOpen ? "no-logo" : "mobile-nav-logo"}>
              <SvgIconHandler type="logo-small" width="192px" height="27.9px" viewBox="0 0 192 27.9" />
            </Link>
            {isScrolled && pageType === "section-page" && (
              <>
                <span styleName="section-name">{sectionName}</span>
              </>
            )}
          </div>
          <div styleName={searchbarNavList} style={hideButtonDiv}>
            {pageType === "section-page" ? (
              <div styleName={isSearchBarOpen ? "alter-nav-menus" : "nav-menus"}>
                <MenuGroup menuList={topNavMenu.slice(0, 3)} menuStyle="nav-menu-link" />
              </div>
            ) : (
              pageType !== "home-page" && (
                <div styleName={searchBarNavMenus}>
                  <MenuGroup menuList={topNavMenu.slice(0, 3)} menuStyle="nav-menu-link" />
                </div>
              )
            )}
            <Link href={`${SUBSCRIPTIONS_LINK}?clientId=${clientId}`}>
              <button id="subscribe-btn" styleName="subscribe__btn">
                SUBSCRIPTIONS
              </button>
            </Link>
            {member ? (
              <UserProfile />
            ) : (
              <>
                <Button styleName="login_profile_icon" onClick={loginHandler}>
                  <SvgIconHandler type="user-icon" width="32px" height="32px" viewBox="0 0 32 32" />
                </Button>
                <Button id="login-btn" styleName="login__btn" onClick={loginHandler}>
                  LOGIN
                </Button>
              </>
            )}
            <Button id="search-btn" styleName="search__icon" onClick={toggleSearchBar}>
              <SvgIconHandler type="search" width="32px" height="32px" viewBox="0 0 32 32" />
            </Button>
          </div>

          {isSearchBarOpen && (
            <div id="search-bar" styleName={isSearchBarOpen && "nav__search"}>
              <NavbarSearch handleToggle={toggleSearchBar} />
            </div>
          )}
        </nav>
        {isHamburgerMenuOpen && (
          <div id="hamburger-menu" styleName="hamburger-menu">
            <SideMenu
              handleToggle={toggleHamMenu}
              isLoggedInUser={!!member}
              handleUserLogin={loginHandler}
              isScrolled={isScrolled}
              deviceType={deviceType}
              topDivHeight={topDivHeight}
            />
          </div>
        )}
      </div>
    </>
  );
}

NavBar.propTypes = {
  isScrolled: PT.bool,
};

export default NavBar;
