import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { SvgIconHandler } from "../../../atoms/svg-icon-hadler";
import { MEMBER_UPDATED } from "../../../store/actions";
import { logoutHandler, openUserDetailsPage } from "../../../paywall";

import "./user-profile.m.css";

export const UserProfile = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);

  useEffect(() => {
    const closeDropdown = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    document.body.addEventListener("click", closeDropdown);

    return () => {
      document.body.removeEventListener("click", closeDropdown);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  const handleLogout = () => {
    dispatch({ type: MEMBER_UPDATED, member: null });
    logoutHandler();
  };

  return (
    <div styleName="user-profile-container">
      <div id="users"></div>
      <div styleName="user-profile-icon" onClick={toggleDropdown}>
        <SvgIconHandler type="user-icon" width="32px" height="32px" viewBox="0 0 32 32" />
      </div>

      {isOpen && (
        <ul styleName="user-profile-options" ref={dropdownRef}>
          <li styleName="user-profile-item" onClick={openUserDetailsPage}>
            Profile
          </li>
          <li styleName="user-profile-item" onClick={handleLogout}>
            Logout
          </li>
        </ul>
      )}
    </div>
  );
};
