import React from "react";
import { SvgIconHandler } from "../svg-icon-hadler";
import "./nav-more-close.m.css";

export const NavMoreClose = () => {
  return (
    <div styleName="close-wrapper">
      <span styleName="close-label">CLOSE</span>
      <span styleName="icon-color">
        <SvgIconHandler type="close-icon" width="24px" height="24px" viewBox="0 0 24 24" />
      </span>
    </div>
  );
};
