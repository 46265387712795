import React from "react";
import PT from "prop-types";
import get from "lodash/get";
import { Link } from "@quintype/components";
import { SvgIconHandler } from "../../../atoms/svg-icon-hadler";
import "./home-menu-item.m.css";

function HomeMenuItem({ item, toggleHandler, textColor = "#0c1215", menuStyle = "menu-link" }) {
  const iconCode = get(item, ["data", "icon-code"]) || "";

  const iconDimension =
    iconCode === "new-label"
      ? {
          width: "34px",
          height: "14px",
          viewBox: "0 0 34 14",
        }
      : {
          width: "24px",
          height: "24px",
          viewBox: "0 0 24 24",
        };

  const featuredIcon = {
    width: "16px",
    height: "16px",
    viewBox: "0 0 16 16",
  };

  if (item["item-type"] === "placeholder") {
    return (
      <div styleName="placeholder-link">
        <span styleName={menuStyle} style={{ color: textColor }}>
          {item.title}
        </span>
        {iconCode && (
          <span style={{ color: textColor }}>
            <SvgIconHandler
              type={iconCode}
              width={featuredIcon.width}
              height={featuredIcon.height}
              viewBox={featuredIcon.viewBox}
            />
          </span>
        )}
      </div>
    );
  } else if (item["item-type"] === "link") {
    return (
      <a
        styleName={menuStyle}
        onClick={toggleHandler}
        className={menuStyle}
        target="_blank"
        rel="noopener noreferrer"
        href={item.completeUrl || "/"}
      >
        <span style={{ color: textColor }}>{item.title}</span>
        {iconCode && (
          <span style={{ color: textColor }}>
            <SvgIconHandler
              type={iconCode}
              width={iconDimension.width}
              height={iconDimension.height}
              viewBox={iconDimension.viewBox}
            />
          </span>
        )}
      </a>
    );
  } else {
    return (
      <Link
        styleName={menuStyle}
        aria-label="menu-item"
        callback={toggleHandler}
        className={menuStyle}
        href={item.completeUrl || "/"}
      >
        <>
          <span style={{ color: textColor }}>{item.title}</span>
          {iconCode && (
            <span style={{ color: textColor }}>
              <SvgIconHandler
                type={iconCode}
                width={iconDimension.width}
                height={iconDimension.height}
                viewBox={iconDimension.viewBox}
              />
            </span>
          )}
        </>
      </Link>
    );
  }
}

export default HomeMenuItem;

HomeMenuItem.propTypes = {
  item: PT.object,
  showIcon: PT.bool,
  toggleHandler: PT.func,
  menuStyle: PT.string,
  changeTextColor: PT.bool,
  changeTextWeight: PT.bool,
  temperature: PT.string,
  textColor: PT.string,
};
