import get from "lodash/get";
import { getStoryLabel } from "../utils/story";
const API_URL = "https://sandbox-api.conscent.in/api/v2";
export const PAYWALL_WRAPPER_ID = "paywall-container";
export const SUBSCRIPTIONS_LINK = "https://sandbox-slp-v2.netlify.app/test";

export const initializeGoogleOneTap = (story, clientId) => {
  const csc = window._csc;
  console.log("initializeGoogleOneTap --->", clientId);
  csc("google-one-tap", {
    clientId: clientId,
    contentId: story?.id,
    successCallback: (data) => {
      console.log("google-one-tap successCallback --->", data.loggedIn);
    },
    oneTapArgs: (data) => console.log("google-one-tap oneTapArgs --->", data),
  });
};

export const initializePaywall = (story, showPaywallBanner, clientId) => {
  const csc = window._csc;

  async function handleSuccessfulInitialization(validationObject) {
    // Function to show the premium content to the User since they have paid for it via ConsCent
    // Here you should verify the  validationObject with our backend
    // And then you must provide access to the user for the complete content

    // example verification code:
    const xhttp = new XMLHttpRequest(); // using vanilla javascript to make a post request
    const url = `${API_URL}/content/consumption/${validationObject.consumptionId}`;
    xhttp.open("POST", url, true);
    // e is the response event
    xhttp.onload = (e) => {
      const backendConfirmationData = JSON.parse(e.target.response);

      // verifying that the validation received matches the backend data
      if (
        validationObject.consumptionId === backendConfirmationData.consumptionId &&
        validationObject.payload.clientId === backendConfirmationData.payload.clientId &&
        validationObject.payload.contentId === backendConfirmationData.payload.contentId
      ) {
        // Validation successful
        console.log("successful validation --->", backendConfirmationData);
        // accessContent would be your function that will do all the actions that need to be done to unlock the entire content
        showPaywallBanner(false);
      }
    };
    xhttp.send();
  }

  const storyTags = story?.tags?.map((tag) => tag.slug) || [];
  const labels = get(story, ["metadata", "story-attributes", "label"], []);
  const tags = [...storyTags, ...labels];
  csc("show");
  csc("init", {
    storyId: story?.id,
    clientId: clientId,
    label: getStoryLabel(story),
    title: story?.headline,
    tags: tags,
    sections: story?.sections?.map((section) => section.slug),
    authorName: story?.["author-name"],
    publicationDate: new Date(story?.["published-at"]).toISOString(),
    successCallback: handleSuccessfulInitialization,
    wrappingElementId: `${PAYWALL_WRAPPER_ID}_${story.id}`,
  });
};

export const loginHandler = () => {
  if (window._csc) {
    const csc = window._csc;
    csc("login-with-redirect");
  }
};

export const getUserDetails = (onSuccessUserDetailsCallback) => {
  if (window._csc) {
    const csc = window._csc;
    csc("add-auth-state-listener");
    csc("get-user-details", {
      successCallbackForUserDetails: onSuccessUserDetailsCallback,
    });
  }
};

export const openUserDetailsPage = () => {
  const csc = window._csc;
  csc("open-user-details-page", {
    onSuccess: (data) => {
      console.log("onSuccess response from openUserDetailsPage --->", data);
    },
  });
};

export const logoutHandler = () => {
  if (window._csc) {
    const csc = window._csc;
    csc("logout");
  }
};
